import { jwtDecode } from "jwt-decode";
import { LatLng } from "leaflet";
import { initialAppStateString } from "./InitialAppState";
import { AppStateType, PopUpState, ScreenState } from "./Types";
import { RoadApi, TopologyApi, VertexApi } from "./client/lib/apis";
import { AuthApi } from "./client/lib/apis/AuthApi";
import { DestinationGroupApi } from "./client/lib/apis/DestinationGroupApi";
import { HousingPlanApi } from "./client/lib/apis/HousingPlanApi";
import { ProjectApi } from "./client/lib/apis/ProjectApi";
import { ResultBlockApi } from "./client/lib/apis/ResultBlockApi";
import { ScenarioApi } from "./client/lib/apis/ScenarioApi";
import { TokenApi } from "./client/lib/apis/TokenApi";
import { UserMeApi } from "./client/lib/apis/UserMeApi";
import { UsersApi } from "./client/lib/apis/UsersApi";
import { UserMe } from "./client/lib/models/UserMe";
import { Configuration } from "./client/lib/runtime";
import { AlertsStateHandler } from "./components/AlertsStateHandler";
import { ProjectBarStateHandler } from "./components/ProjectBarStateHandler";
import { AdminStateHandler } from "./screens/admin/AdminStateHandler";
import { LoginStateHandler } from "./screens/auth/LoginStateHandler";
import { CreateCustomAnalysisLayersStateHandler } from "./screens/conf/CreateCustomAnalysisLayersStateHandler";
import { CustomAnalysisLayersStateHandler } from "./screens/conf/CustomAnalysisLayersStateHandler";
import { NewProjectStateHandler } from "./screens/project/NewProjectStateHandler";
import { ProjectStateHandler } from "./screens/project/ProjectStateHandler";
import { EditHousingPlanAreasStateHandler } from "./screens/scenario/EditHousingPlanAreasStateHandler";
import { EditNetworkStateHandler } from "./screens/scenario/EditNetworkStateHandler";
import { NewScenarioStateHandler } from "./screens/scenario/NewScenarioStateHandler";
import { ScenarioResultStateHandler } from "./screens/scenario/ScenarioResultStateHandler";
export class AppStateHandler
  implements
    ScenarioResultStateHandler,
    LoginStateHandler,
    ProjectBarStateHandler,
    ProjectStateHandler,
    NewProjectStateHandler,
    NewScenarioStateHandler,
    EditNetworkStateHandler,
    AlertsStateHandler,
    CreateCustomAnalysisLayersStateHandler,
    EditHousingPlanAreasStateHandler
{
  public state: AppStateType = JSON.parse(initialAppStateString);

  public basePath: () => string = () => {
    let basePath: string = window.location.href;
    basePath = basePath.replace("localhost:3000", "localhost:8000");
    basePath = basePath.replace(
      "scenario-manager.stratopo.nl",
      "scenario-manager-api.stratopo.nl"
    );
    basePath = basePath.replace(
      "scenario-manager-dev.stratopo.nl",
      "scenario-manager-api-dev.stratopo.nl"
    );
    // Remove trailing slash if any
    if (basePath.substring(basePath.length - 1) === "/") {
      basePath = basePath.substring(0, basePath.length - 1);
    }
    return basePath;
  };

  // API
  private apiConfiguration = new Configuration({
    basePath: this.basePath(),
    accessToken: () => {
      return this.state.auth.accessToken || "";
    },
    // credentials: "include",
  });
  public tokenApi = new TokenApi(this.apiConfiguration);
  public authApi = new AuthApi(this.apiConfiguration);
  public projectApi = new ProjectApi(this.apiConfiguration);
  public UserMeApi = new UserMeApi(this.apiConfiguration);
  public usersApi = new UsersApi(this.apiConfiguration);
  public scenarioApi = new ScenarioApi(this.apiConfiguration);
  public resultBlockApi = new ResultBlockApi(this.apiConfiguration);
  public roadApi = new RoadApi(this.apiConfiguration);
  public vertexApi = new VertexApi(this.apiConfiguration);
  public topologyApi = new TopologyApi(this.apiConfiguration);
  public destinationGroupApi = new DestinationGroupApi(this.apiConfiguration);
  public HousingPlanApi = new HousingPlanApi(this.apiConfiguration);

  public getState = () => {
    return this.state;
  };

  appAlreadyInitialized = false;
  public initApp = (updateState: (newState: AppStateType) => void) => {
    if (!this.appAlreadyInitialized) {
      this.appAlreadyInitialized = true;
      const refreshToken = localStorage.getItem("token");
      if (refreshToken !== null) {
        this.tokenApi
          .authTokenRefreshCreate({
            tokenRefreshRequest: { refresh: refreshToken },
          })
          .then((response) => {
            if (response.access !== null && response.refresh !== null) {
              this.setTokens(response.access, response.refresh, updateState);
              this.loadProjects(() => {
                const has_projects = this.state.projects.length > 0;

                if (has_projects) {
                  this.changeScreen(this.state.screenState, () => {
                    this.current_user(updateState);
                  });
                } else {
                  this.changeScreen(ScreenState.NewProject, () => {
                    this.current_user(updateState);
                  });
                }
              });
            }
            //else => sign out
          })
          .catch((error) => {
            console.log("Error while refreshing", error);
            //sign out
          });
      }
    }
  };
  public closePopUp = (updateState: (newState: AppStateType) => void) => {
    this.state.popUpState = PopUpState.Hidden;
    updateState(this.state);
  };
  public current_user = (updateState: (newState: AppStateType) => void) => {
    this.UserMeApi.authUsersMeRetrieve().then((response: UserMe) => {
      this.state.current_user.email = response.email || "";
      this.state.current_user.is_staff = response.isStaff || false;
      this.state.current_user.center_area = new LatLng(
        response.centerArea.coordinates[1],
        response.centerArea.coordinates[0]
      );
      if (this.state.current_user.email !== "") {
        this.state.current_user.name =
          this.state.current_user.email?.split("@")[0] || "";
      }
      updateState(this.state);
    });
  };

  public changeScreen = (
    newScreenState: ScreenState,
    updateState: (newState: AppStateType) => void
  ) => {
    console.log("changeScreen", newScreenState);
    switch (newScreenState) {
      // Project Screens
      case ScreenState.Project:
        this.initProjectScreen(updateState);
        break;
      case ScreenState.NewProject:
        this.initNewProjectScreen(updateState);
        break;

      // Scenario Screens
      case ScreenState.ScenarioResult:
        this.initScenarioResultScreen(updateState);
        break;
      case ScreenState.NewScenario:
        this.initNewScenarioScreen(updateState);
        break;
      case ScreenState.EditNetworkScreen:
        this.initEditNetworkScreen(updateState);
        break;
      case ScreenState.EditHousingPlanAreasScreen:
        this.initEditHousingPlanAreasScreen(updateState);
        break;

      // Custom Analysis Layers screens
      case ScreenState.CreateCustomAnalysisLayersScreen:
        this.initCreateCustomAnalysisLayersScreen(updateState);
        break;
      case ScreenState.CustomAnalysisLayersScreen:
        this.initCustomAnalysisLayersScreen(updateState);
        break;

      // Admin Screen
      case ScreenState.AdminScreen:
        this.initAdminScreen(updateState);
        break;

      default:
        this.state.screenState = newScreenState;
        updateState(this.state);
        break;
    }
  };

  public setTokens = (
    accessToken: string,
    refreshToken: string,
    updateState: (newState: AppStateType) => void
  ) => {
    const secondsMargin = 30; // 30 seconds before token expires a refresh request will be made
    this.state.auth.accessToken = accessToken;
    localStorage.setItem("token", refreshToken);

    this.state.loggedIn = true;
    updateState(this.state);

    // Decode the access token
    const decoded = jwtDecode(this.state.auth.accessToken) as {
      [key: string]: any;
    };
    if ("exp" in decoded) {
      const currentUnixTime = Math.floor(Date.now() / 1000);
      const secondsLeft = decoded.exp - currentUnixTime;
      const refreshInMs = Math.max((secondsLeft - secondsMargin) * 1000, 1);

      // Set a timer to refresh the access token
      setTimeout(() => {
        const refreshToken = localStorage.getItem("token");
        if (refreshToken !== null) {
          this.tokenApi
            .authTokenRefreshCreate({
              tokenRefreshRequest: { refresh: refreshToken },
            })
            .then((response) => {
              if (response.access !== null && response.refresh !== null) {
                this.setTokens(response.access, response.refresh, updateState);
              } else {
                console.log("Refresh tokens failed");
                //sign out
                this.state = JSON.parse(initialAppStateString);
                updateState(this.state);
              }
            })
            .catch((error) => {
              console.log("Error while refreshing", error);
              //sign out
              this.state = JSON.parse(initialAppStateString);
              updateState(this.state);
            });
        }
      }, refreshInMs);
    }
  };

  public logout = (updateState: (newState: AppStateType) => void) => {
    const refreshToken = localStorage.getItem("token");
    this.authApi.authLogoutCreate({
      logout: { refreshToken: refreshToken || "" },
    });
    localStorage.removeItem("token");
    this.state = JSON.parse(initialAppStateString);
    updateState(this.state);
  };

  // Project bar
  loadProjects = ProjectBarStateHandler.prototype.loadProjects;
  changeSelectedProject =
    ProjectBarStateHandler.prototype.changeSelectedProject;
  changeSelectedScenario =
    ProjectBarStateHandler.prototype.changeSelectedScenario;
  projectBarCreateNewScenario =
    ProjectBarStateHandler.prototype.projectBarCreateNewScenario;

  // Login
  loginFormEmailChange = LoginStateHandler.prototype.loginFormEmailChange;
  loginFormPasswordChange = LoginStateHandler.prototype.loginFormPasswordChange;
  login = LoginStateHandler.prototype.login;

  // ScenarioResult screen
  initScenarioResultScreen =
    ScenarioResultStateHandler.prototype.initScenarioResultScreen;
  fetchResultBlocks = ScenarioResultStateHandler.prototype.fetchResultBlocks;
  deleteScenario = ScenarioResultStateHandler.prototype.deleteScenario;
  exportBlock = ScenarioResultStateHandler.prototype.exportBlock;
  // Project screen
  initProjectScreen = ProjectStateHandler.prototype.initProjectScreen;
  getProject = ProjectStateHandler.prototype.getProject;
  deleteProject = ProjectStateHandler.prototype.deleteProject;

  // New project screen
  initNewProjectScreen = NewProjectStateHandler.prototype.initNewProjectScreen;
  updateNewProjectName = NewProjectStateHandler.prototype.updateNewProjectName;
  saveNewProject = NewProjectStateHandler.prototype.saveNewProject;
  removeSelectedDestinationGroup =
    NewProjectStateHandler.prototype.removeSelectedDestinationGroup;
  addSelectedDestinationGroup =
    NewProjectStateHandler.prototype.addSelectedDestinationGroup;
  fetchDestinationGroupList =
    NewProjectStateHandler.prototype.fetchDestinationGroupList;
  fetchTopologyList = NewProjectStateHandler.prototype.fetchTopologyList;

  // New Scenario screen
  initNewScenarioScreen =
    NewScenarioStateHandler.prototype.initNewScenarioScreen;
  updateNewScenarioName =
    NewScenarioStateHandler.prototype.updateNewScenarioName;
  saveNewScenario = NewScenarioStateHandler.prototype.saveNewScenario;

  // Create scenario screen
  initEditNetworkScreen =
    EditNetworkStateHandler.prototype.initEditNetworkScreen;
  calculateScenario = EditNetworkStateHandler.prototype.calculateScenario;
  reloadBaseNetwork = EditNetworkStateHandler.prototype.reloadBaseNetwork;
  editNetworkMapMoved = EditNetworkStateHandler.prototype.editNetworkMapMoved;
  editNetworkMapChangeDrawMode =
    EditNetworkStateHandler.prototype.editNetworkMapChangeDrawMode;
  editNetworkAddRoad = EditNetworkStateHandler.prototype.editNetworkAddRoad;
  editNetworkEditRoad = EditNetworkStateHandler.prototype.editNetworkEditRoad;
  editNetworkUpdateSpeed =
    EditNetworkStateHandler.prototype.editNetworkUpdateSpeed;
  editNetworkSaveRoad = EditNetworkStateHandler.prototype.editNetworkSaveRoad;
  editNetworkLoadEditedRoads =
    EditNetworkStateHandler.prototype.editNetworkLoadEditedRoads;
  editNetworkLoadEditedVertices =
    EditNetworkStateHandler.prototype.editNetworkLoadEditedVertices;
  editNetworkDeleteBaseRoad =
    EditNetworkStateHandler.prototype.editNetworkDeleteBaseRoad;
  editNetworkDeleteRoad =
    EditNetworkStateHandler.prototype.editNetworkDeleteRoad;
  editNetworkDeleteEditedVertex =
    EditNetworkStateHandler.prototype.editNetworkDeleteEditedVertex;
  editNetworkClearMap = EditNetworkStateHandler.prototype.editNetworkClearMap;
  updateRoadInfo = EditNetworkStateHandler.prototype.updateRoadInfo;
  editNetworkAddVertex = EditNetworkStateHandler.prototype.editNetworkAddVertex;

  // Alerts
  addToAlerts = AlertsStateHandler.prototype.addToAlerts;
  removeFromAlerts = AlertsStateHandler.prototype.removeFromAlerts;
  displayAlert = AlertsStateHandler.prototype.displayAlert;

  // Create new analysis layer
  initCreateCustomAnalysisLayersScreen =
    CreateCustomAnalysisLayersStateHandler.prototype
      .initCreateCustomAnalysisLayersScreen;
  saveAnalysisLayer =
    CreateCustomAnalysisLayersStateHandler.prototype.saveAnalysisLayer;

  // CustomAnalysisLayersStateHandler
  initCustomAnalysisLayersScreen =
    CustomAnalysisLayersStateHandler.prototype.initCustomAnalysisLayersScreen;
  loadCustomAnalysisLayers =
    CustomAnalysisLayersStateHandler.prototype.loadCustomAnalysisLayers;
  DeleteCustomAnalysisLayer =
    CustomAnalysisLayersStateHandler.prototype.DeleteCustomAnalysisLayer;
  //EditHousingPlanAreasScreen
  load_HousingPlanAreas =
    EditHousingPlanAreasStateHandler.prototype.load_HousingPlanAreas;
  initEditHousingPlanAreasScreen =
    EditHousingPlanAreasStateHandler.prototype.initEditHousingPlanAreasScreen;
  create_HousingPlanArea =
    EditHousingPlanAreasStateHandler.prototype.create_HousingPlanArea;
  deleteHousingPlanArea =
    EditHousingPlanAreasStateHandler.prototype.deleteHousingPlanArea;
  cleanAllHousingPlanAreas =
    EditHousingPlanAreasStateHandler.prototype.cleanAllHousingPlanAreas;
  calculateHousingPlan =
    EditHousingPlanAreasStateHandler.prototype.calculateHousingPlan;
  updateHousingPlanArea =
    EditHousingPlanAreasStateHandler.prototype.updateHousingPlanArea;

  // Admin screen
  initAdminScreen = AdminStateHandler.prototype.initAdminScreen;
  updateNewUser = AdminStateHandler.prototype.updateNewUser;
  createNewUser = AdminStateHandler.prototype.createNewUser;
  loadUsers = AdminStateHandler.prototype.loadUsers;
  loadUserlogs = AdminStateHandler.prototype.loadUserlogs;
}
