import { LatLng } from "leaflet";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import { ScenarioTypeEnum } from "../../client/lib/models";

export class ScenarioResultStateHandler {
  // result screen
  initScenarioResultScreen(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    // Navigate to this screen and reset the info
    this.state.scenarioScreen.name = "";
    this.state.scenarioScreen.loading = false;
    // this.state.scenarioScreen.category = CategoryEnum;
    // this.state.scenarioScreen.scenarioType = ScenarioTypeEnum.BicycleOrientedDevelopment;
    // this.state.newScenario.scenarioType = selectedScenario.scenarioType!;
    this.state.scenarioScreen.scenarioTypeInDutch = "";
    this.state.resultBlocks = [];
    this.state.screenState = ScreenState.ScenarioResult;
    updateState(this.state);

    // Find the scenario based on the project and scenario IDs
    console.log(
      "SELECTED SCENARIO",
      this.state.selectedProject,
      this.state.selectedScenario
    );
    if (
      this.state.selectedProject !== null &&
      this.state.selectedScenario !== null
    ) {
      const projectIndex = this.state.projects.findIndex(
        (project) => project.id === this.state.selectedProject
      );
      if (projectIndex > -1) {
        const scenarioIndex = this.state.projects[
          projectIndex
        ].scenarioList.findIndex(
          (scenario) => scenario.id === this.state.selectedScenario
        );
        if (scenarioIndex > -1) {
          const selectedScenario =
            this.state.projects[projectIndex].scenarioList[scenarioIndex];

          // Load the information from the scenario
          this.state.scenarioScreen.name = selectedScenario.name;
          this.state.scenarioScreen.category = selectedScenario.category!;
          this.state.scenarioScreen.scenarioType =
            selectedScenario.scenarioType!;
          this.state.newScenario.scenarioType = selectedScenario.scenarioType!;
          switch (this.state.scenarioScreen.scenarioType) {
            case ScenarioTypeEnum.BicycleOrientedDevelopment:
              this.state.scenarioScreen.scenarioTypeInDutch =
                "Fietsgericht ontwikkelen";
              break;
            case ScenarioTypeEnum.AccessibilityInView:
              this.state.scenarioScreen.scenarioTypeInDutch =
                "Bereikbaarheid in beeld";
              break;
          }
          updateState(this.state);

          // Fetch the results
          this.fetchResultBlocks(updateState);
          this.editNetworkClearMap(updateState);
        }
      }
    }
  }

  fetchResultBlocks(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    // Call the updateState function with the current state
    updateState(this.state);

    // Initialize the handled variable
    let handled: number = 0;

    /**
     * Checks if the scenario has been handled.
     * If not, it recursively calls itself after a timeout.
     */
    const checkHandled = () => {
      if (this.state.selectedScenario) {
        // Retrieve the scenario from the API
        this.scenarioApi
          .apiScenarioRetrieve({ id: this.state.selectedScenario })
          .then((response) => {
            // Update the handled variable with the response
            this.state.scenarioScreen.latLng = new LatLng(
              response.lat,
              response.lon
            );

            updateState(this.state);
            handled = response.handled as number;

            // Retrieve the result blocks from the API
            if (this.state.selectedScenario) {
              this.resultBlockApi
                .apiResultblockList({
                  scenario: this.state.selectedScenario,
                })
                .then((response) => {
                  // Update the result blocks in the state if the scenario matches
                  if (response.length > 0) {
                    if (response[0].scenario === this.state.selectedScenario) {
                      this.state.resultBlocks = response;
                      updateState(this.state);
                    }
                  } else {
                    this.state.resultBlocks = [];
                    updateState(this.state);
                  }
                });
            }

            // If the scenario hasn't been handled, set loading to true and call checkHandled after a timeout
            if (handled < 2) {
              console.log("Scenario not handled yet", handled);
              this.state.scenarioScreen.loading = true;
              updateState(this.state);
              setTimeout(() => {
                checkHandled();
              }, 5000);
            } else {
              // If the scenario has been handled, set loading to false
              console.log("Scenario has been handled", handled);
              this.state.scenarioScreen.loading = false;
              updateState(this.state);
            }
          });
      }
    };

    // Call checkHandled to start fetching the result blocks
    checkHandled();
  }

  exportBlock(
    this: AppStateHandler,
    blockId: number,
    bloockName: string,
    updateState: (newState: AppStateType) => void
  ) {
    const alert_id = this.addToAlerts(
      "🖼️ Bezig met het voorbereiden van uw export!",
      updateState
    );
    updateState(this.state);
    this.resultBlockApi
      .apiResultblockExportRetrieve({ id: blockId })
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download =
          blockId +
          "_" +
          this.state.scenarioScreen.name?.replace(/ /g, "_") +
          "_" +
          bloockName.replace(/ /g, "_") +
          ".png";
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
        document.body.removeChild(link);
        this.removeFromAlerts(alert_id, updateState);
      });
  }

  deleteScenario(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.scenarioApi
      .apiScenarioDestroy({ id: this.state.selectedScenario as number })
      .then((response) => {
        this.state.projects = [];
        this.loadProjects(() => {
          this.initScenarioResultScreen(updateState);
        });
        updateState(this.state);
      });
  }
}
