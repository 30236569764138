import { LatLng } from "leaflet";
import { AppStateHandler } from "src/AppStateHandler";
import { AppStateType, HousingPlanArea, ScreenState } from "src/Types";
import { HousingTypeEnum } from "../../client/lib/models";

export class EditHousingPlanAreasStateHandler {
  initEditHousingPlanAreasScreen(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.EditHousingPlanAreasScreen.HousingPlanAreas = [];

    this.state.screenState = ScreenState.EditHousingPlanAreasScreen;
    this.load_HousingPlanAreas(updateState);
    updateState(this.state);
  }

  load_HousingPlanAreas(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    // this.state.EditHousingPlanAreasScreen.HousingPlanAreas = [];
    this.HousingPlanApi.apiHousingPlanList({
      scenario: this.state.selectedScenario as number,
    }).then((response) => {
      updateState(this.state);
      response.forEach((housingplanArea) => {
        const newHousingPlanArea: HousingPlanArea = {
          pk: housingplanArea.id,
          name: housingplanArea.name,
          coordinates: housingplanArea.geom.coordinates[0].map(
            (coord) => new LatLng(coord[1], coord[0])
          ),
          mousePosition: null,
          areaClosed: true,
          number_of_houses: housingplanArea.numberOfHouses,
          housing_type: housingplanArea.housingType,
        };

        if (
          this.state.EditHousingPlanAreasScreen.HousingPlanAreas.findIndex(
            (item) => item.pk === newHousingPlanArea.pk
          ) === -1
        ) {
          this.state.EditHousingPlanAreasScreen.HousingPlanAreas.push(
            newHousingPlanArea
          );
        }
      });
    });

    updateState(this.state);
  }
  create_HousingPlanArea(
    this: AppStateHandler,
    newHousingPlanArea: HousingPlanArea,
    updateState: (newState: AppStateType) => void
  ) {
    this.HousingPlanApi.apiHousingPlanCreate({
      housingPlanRequest: {
        scenario: this.state.selectedScenario as number,
        geom: {
          type: "Polygon",
          coordinates: [
            newHousingPlanArea.coordinates.map((c) => [c.lng, c.lat]),
          ],
        },
        name: newHousingPlanArea.name as string,
        numberOfHouses: newHousingPlanArea.number_of_houses as number,
        housingType: newHousingPlanArea.housing_type,
      },
    }).then((response) => {
      this.load_HousingPlanAreas(updateState);
      this.state.EditHousingPlanAreasScreen.newHousingPlanArea = {
        pk: 0,
        name: "",
        coordinates: [],
        mousePosition: null,
        areaClosed: false,
        number_of_houses: 0,
        housing_type: HousingTypeEnum.Apartment,
      };
      this.state.EditHousingPlanAreasScreen.NewHousingPlanAreaPopUpVisable =
        false;
      updateState(this.state);
    });
    updateState(this.state);
  }
  deleteHousingPlanArea(
    this: AppStateHandler,
    housingPlanArea_id: number,
    updateState: (newState: AppStateType) => void
  ) {
    if (housingPlanArea_id === 0) return;
    this.HousingPlanApi.apiHousingPlanDestroy({
      id: housingPlanArea_id,
    })
      .then((response) => {
        this.state.EditHousingPlanAreasScreen.HousingPlanAreas = [];
        updateState(this.state);
        this.load_HousingPlanAreas(updateState);
      })
      .catch((error) => {
        console.log("Error while deleting housing plan area", error);
      });
    updateState(this.state);
  }
  updateHousingPlanArea(
    this: AppStateHandler,
    housingPlanArea_id: number,
    updateState: (newState: AppStateType) => void
  ) {
    if (housingPlanArea_id === 0) return;
    if (
      this.state.EditHousingPlanAreasScreen.EditHousingPlanArea?.name === "" ||
      this.state.EditHousingPlanAreasScreen.EditHousingPlanArea
        ?.number_of_houses === 0 ||
      this.state.EditHousingPlanAreasScreen.EditHousingPlanArea
        ?.housing_type === undefined
    ) {
      return;
    }
    this.HousingPlanApi.apiHousingPlanUpdate({
      id: housingPlanArea_id,
      housingPlanObjectRequest: {
        name: this.state.EditHousingPlanAreasScreen.EditHousingPlanArea.name,
        numberOfHouses:
          this.state.EditHousingPlanAreasScreen.EditHousingPlanArea
            .number_of_houses,
        housingType:
          this.state.EditHousingPlanAreasScreen.EditHousingPlanArea
            .housing_type,
      },
    })
      .then((response) => {
        this.state.EditHousingPlanAreasScreen.HousingPlanAreas = [];
        this.state.EditHousingPlanAreasScreen.EditHousingPlanAreaPopUpVisable =
          false;
        updateState(this.state);
        this.load_HousingPlanAreas(updateState);
      })
      .catch((error) => {
        console.log("Error while updating housing plan area", error);
      });
    updateState(this.state);
  }
  cleanAllHousingPlanAreas(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.EditHousingPlanAreasScreen.HousingPlanAreas.forEach((area) => {
      this.deleteHousingPlanArea(area.pk, updateState);
    });
    this.state.EditHousingPlanAreasScreen.HousingPlanAreas = [];
    updateState(this.state);
  }

  calculateHousingPlan(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    if (this.state.selectedScenario) {
      this.changeScreen(ScreenState.ScenarioResult, updateState);
      this.scenarioApi
        .apiScenarioCalcCreate({ id: this.state.selectedScenario })
        .then((result) => {
          this.displayAlert("Scenario berekening gestart.", 5000, updateState);
        })
        .catch((error) => {
          console.log("Error while starting calculation", error);
          this.displayAlert(
            "Fout tijdens het starten van de scenario berekening.",
            5000,
            updateState
          );
        });
    }
  }
}
